import React from 'react'
import Layout from '../components/layout'
import { Link} from 'gatsby';
import Testimonials from '../components/testimonials';
import LetsWorkTogether from '../components/letsWorkTogether';
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';

export default ({pageContext}) => (
	<Layout>
	{pageContext.acf.schema_product_name && pageContext.acf.schema_product_rating && pageContext.acf.schema_review_count &&
	<Helmet>
	<script type="application/ld+json">
		{`
			{ "@context": "http://schema.org",
			"@type": "Product",
			"name": "${pageContext.acf.schema_product_name}",
			"brand": "Webplace",
			"aggregateRating":
				{"@type": "AggregateRating",
				"ratingValue": "${pageContext.acf.schema_product_rating}",
				"reviewCount": "${pageContext.acf.schema_review_count}"
				}
			}
		`}
	</script>
	</Helmet>
	}
	{pageContext.yoast_meta.map((meta,key) => 
		<>
		{meta.name === 'description' &&
		<SEO description={meta.content} title={pageContext.title} />		
		}
		</>
	)} 
	
	<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
		<div className="container">
			<ol className="breadcrumb mb-0 py-2 px-0">
				<li className="breadcrumb-item"><a href="/">Home</a></li>
				<li className="breadcrumb-item active" dangerouslySetInnerHTML={{ __html:pageContext.title}} />
			</ol>
		</div>
	</nav>
	<strong className="sr-only">This is main content</strong>
	<section className="intro-section py-10 pt-lg-16 pb-lg-19">
		<div className="container">
			<div className="row align-items-center justify-content-between">
				<div className="col-12 col-md-7 col-lg-7 mb-8 mb-md-0">
					<h1 className="mb-3 mb-lg-6 fw-800" dangerouslySetInnerHTML={{ __html:pageContext.title}} />
					{pageContext.acf.banner_title.length > 0 && 
					<p className="mb-0 mb-lg-10">{pageContext.acf.banner_title}</p>
					}
				</div>

				
			</div>
		</div>
	</section>
	
	<div className="offwhite-bg position-relative pb-20">
		<div className="container d-print-none viewport-section">
			<div className="content-box bg-sky-blue style-2 mb-8 mb-lg-12 fadeInUp">
				<div className="row w-md-100">
					<div className="col-md-6 position-relative d-print-none">
						{pageContext.acf.title_one_image &&
						
							<Img
							fixed={pageContext.acf.title_one_image.localFile.childImageSharp.fluid}
							
						/>
							
						
						}
					</div>
					<div className="col-md-6">
						<div className="block-vc d-flex align-items-center p-6 mx-lg-6">
							<div className="wrap fw-400 w-100">
								{pageContext.acf.title_one.length > 0 &&
								<h4 className="mb-5 mb-lg-5 fw-700" dangerouslySetInnerHTML={{ __html: pageContext.acf.title_one}}/>
								}
								{pageContext.acf.title_one_supporting_text.length > 0 &&
								<div dangerouslySetInnerHTML={{ __html: pageContext.acf.title_one_supporting_text}}/>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			{pageContext.acf.highlight_title.length > 0 &&
			<div className="heading-block text-center mb-10 mb-lg-15 fadeInUp">
				<h2 className="h1 fw-700 mb-3 mb-md-5">{pageContext.acf.highlight_title}</h2>
				{pageContext.acf.highlight_text.length > 0 &&
					<div className="mb-0" dangerouslySetInnerHTML={{ __html: pageContext.acf.highlight_text}}/>
				}
			</div>
			}
			{pageContext.acf.highlight_images_list &&
				<ul className="logo-list text-center d-flex flex-wrap align-items-center justify-content-center mx-n3 mb-0 fadeInUp d-print-none pb-6 fadeInUp">
					{pageContext.acf.highlight_images_list.map((images,key) => 
						<>
						{pageContext.acf.highlight_images_list[key].highlight_images &&	
						<li className="px-3 mb-6" key={key}> 
							<img src={`${pageContext.acf.highlight_images_list[key].highlight_images.localFile.childImageSharp.fluid.srcWebp}`} alt="" width="" height="" />
						</li> 
						}
						</> 
					)} 
				</ul>
			}
		</div>
		{pageContext.acf.section_2_supporting_proof_points &&
		<Fade triggerOnce="true" direction="top" className="numbered-columns pt-5 pb-10 pt-lg-8 pb-lg-7 viewport-section">
			<div className="container">
				
				
					
					<div className="row align-items-center">
					<div className="col-12 col-lg-6">
					{pageContext.acf.title_two.length > 0 &&
						<div className="wrap pt-0 pb-10 pt-lg-0 pb-lg-30 fadeInUp">
							<h2 className="h2 text-blue mb-2 mb-lg-4 fw-700" dangerouslySetInnerHTML={{ __html: pageContext.acf.title_two}}/>
							<div className="mb-0" dangerouslySetInnerHTML={{ __html: pageContext.acf.title_two_sub_title}}/>
						</div>
					}	
					</div>		
					<div className={pageContext.acf.title_two.length > 0 ? ('col-12 col-lg-6'): 'col-12 col-lg-8 offset-lg-2'}>	
						<ul className="numbered-list list-unstyled pl-1 fadeInUp">
							{pageContext.acf.section_2_supporting_proof_points.map((proof,key) => 
							  <li className="position-relative pl-6 pl-lg-9 mb-8" key={key}>
								<h3 className="h3 mb-2">{proof.supporting_proof_point_title}</h3>
								<div className="mb-0" dangerouslySetInnerHTML={{ __html: proof.supporting_proof_point_short_text}}/>
							  </li>
							)} 
						</ul>		
					</div>
					</div>	
				
						
					
					

					
				
			</div>
		</Fade>
		}
	</div>
	{pageContext.acf.title_three.length > 0 &&
	<Fade triggerOnce="true" direction="top" className="info-list-section viewport-section pt-12 pt-lg-16 pb-10 pb-lg-1">
		<div className="container">
			<div className="heading-block text-center mb-8 mb-lg-15 fadeInUp">
				<h2 className="h1 fw-700 mb-3 mb-md-4 text-blue">{pageContext.acf.title_three}</h2>
				<p className="mb-0">{pageContext.acf.title_three_subtitle}</p>
			</div>
			{pageContext.acf.section_3_supporting_proof_points  &&
			<div className="info-list fadeInUp">
				<div className="row">
					
					{pageContext.acf.section_3_supporting_proof_points.map((proof,key) => (
					
					<div className="col-12 col-md-6 col-lg-4 mb-7 mb-lg-15" key={key}>
							<div className="ico-holder d-flex align-items-center flex-wrap mb-4">
							
							{proof.section_3_supporting_proof_points_image &&
								 <img src={`${proof.section_3_supporting_proof_points_image.localFile.childImageSharp.fluid.srcWebp}`} alt="" width="" height="" />
							}
							 	
							</div>
							<h3 className="h3 mb-2">{proof.section_3_supporting_proof_points_title}</h3>
							<div dangerouslySetInnerHTML={{ __html: proof.section_3_supporting_proof_points_description}}/>
					</div>
		
					
					
					))}					
				</div>
			</div>
			}
		</div>
	</Fade>
	}
	

	{pageContext.acf.services_heading.length > 0 &&
	<Fade triggerOnce="true" direction="top" className="info-list-section viewport-section pt-12 pt-lg-16 pb-10 pb-lg-1">
		<div className="container">
			<div className="heading-block text-center mb-8 mb-lg-15 fadeInUp">
				<h2 className="h1 fw-700 mb-3 mb-md-4 text-blue">{pageContext.acf.services_heading}</h2>
			</div>
			<div className="info-list fadeInUp">
				<div className="row">
					{pageContext.acf.services.map((service,key) => 
					
						<div className="col-12 col-md-6 col-lg-4 mb-7 mb-lg-15" key={key}>
							<h3 className="h3 mb-2">{service.services_title}</h3>
							{service.services_description  &&
							<p className="mb-0">{service.services_description}</p>
							}
						</div>
					)} 

				</div>
			</div>
		</div>
	</Fade>
	}

	{pageContext.acf.tools &&
		<Fade triggerOnce="true" direction="top" className="info-list-section viewport-section pt-12 pt-lg-16 pb-10 pb-lg-1">
			<div className="container">
				{pageContext.acf.tools_heading &&
				<div className="heading-block text-center mb-8 mb-lg-15 fadeInUp">
					<h2 className="h1 fw-700 mb-3 mb-md-4 text-blue">{pageContext.acf.tools_heading}</h2>
				</div>
				}
				<div className="info-list fadeInUp">
					<div className="row">
						{pageContext.acf.tools.map((tool,key) => 
							<div className="col-6 col-md-6 col-lg-3 mb-7 mb-lg-15" key={key}>
								<div className="mx-auto text-center mb-4">
								{tool.tool_image &&	
									<Img
										fixed={tool.tool_image.localFile.childImageSharp.fixed}
										alt={"Icon for" + tool.tool_name}
										width="50"
									/>
								}
								</div>
								<p className="mb-0 mt-6 fw-500 text-center">{tool.tool_name}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</Fade>
	}
	
	

	{pageContext.acf.repeatable_content &&
		
		<Fade triggerOnce="true" direction="top" className="info-list-section viewport-section pt-12 pt-lg-16 pb-10 pb-lg-1">
		{pageContext.acf.repeatable_content.map((repeatable,key) => 
			<div className="container">
				{repeatable.repeatable_content_section_heading &&
				<div className="heading-block text-center mb-8 mb-lg-15 fadeInUp">
					<h2 className="h1 fw-700 mb-3 mb-md-4 text-blue">{repeatable.repeatable_content_section_heading}</h2>
					<p className="mb-0">{repeatable.repeatable_content_section_subheading}</p>
				</div>
				}
				<div className="info-list fadeInUp">
					<div className="row">
						{repeatable.content_section.map((content,key) => 
							
							<div className="col-6 col-md-6 col-lg-3 mb-7 mb-lg-15" key={key}>
								<div className="mx-auto text-center mb-4">
								{content.content_section_image &&	
									<img
										src={content.content_section_image.localFile.publicURL}
										alt={"Icon for" + content.content_section_title}
										style={{  height: '60px' }}
									/>
								}
								</div>
								<p className="mb-0 mt-6 fw-500 text-center">{content.content_section_title}</p>
							</div>
						)}
					</div>
				</div>
			</div>
			)}
		</Fade>
		
	}
	
	

	{pageContext.acf.related_insights &&
		<section className="article-section viewport-section mt-10">
			<div className="container">
				<div className="border-top py-10 py-md-12 pt-lg-12 pb-lg-16 pb-16">
					<h2 className="section-heading text-default text-center mb-5 mb-md-12 fw-700 fadeInUp">Related insights</h2>
					<div className="article-list position-relative row fadeInUp">
					{pageContext.acf.related_insights.map((post,key) => (
					<Fade triggerOnce="true" direction="top" className="col-12 col-md-6 col-lg-4 position-relative mb-5 mb-md-0 post-item" key={key}>
						<div className="card"> 
							<a href={`/blog/${post.post_name}`} className="card-img-top d-block position-relative"> 
							<strong className="sr-only">This is a card image link</strong> 
							
							{post.acf.thumbnail_image &&
								
							<img src={`${post.acf.thumbnail_image.localFile.publicURL}`} alt="" width="" height="" />
							
							}
							</a>
							

							<div className="card-body"> 
								<span class="date">{post.post_date}</span>
								<h2 className="card-title fw-700 text-blue"><Link to={`/blog/${post.post_name}`}><span dangerouslySetInnerHTML={{ __html: post.post_title}} /></Link></h2>
								<p className="card-text" dangerouslySetInnerHTML={{ __html: post.acf.intro_text }}/>
							</div>
						</div>
					</Fade>
					))}
					</div>
					</div>
				</div>
		</section>
		}

	{pageContext.acf.case_studies &&
	<section className="related-case-studies article-section viewport-section mt-10">
		<div className="container">
			<div className="border-top py-10 py-md-12 pt-lg-12 pb-lg-16 pb-16">
				<h2 className="section-heading text-default text-center mb-5 mb-md-12 fw-700 fadeInUp">Related projects</h2>
				<div className="article-list position-relative row fadeInUp">
                {pageContext.acf.case_studies.map((post,key) => (
                <Fade triggerOnce="true" direction="top" className="col-12 col-md-6 col-lg-4 position-relative mb-5 mb-md-0 post-item" key={key}>
                    <div className="card"> 
                        <a href={`/folio/${post.post_name}`} className="card-img-top d-block position-relative"> 
                        <strong className="sr-only">This is a card image link</strong> 
                        <img src={`${post.acf.image.url.localFile.publicURL}`} alt="" width="" height="" /></a>
                        <div className="card-body"> 
                            <span className="category d-block mb-2 fw-500 text-fade-black">{post.post_title}</span>
                            <h3 className="card-title fw-700 text-blue"><Link to={`/folio/${post.post_name}`}>{post.acf.tagline}</Link></h3>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: post.acf.intro }}/>
                        </div>
                    </div>
                </Fade>
                ))}
                </div>
                </div>
            </div>
    </section>
	}
	<div className="decor-wrap position-relative mb-14">
		<div className="container">
			<Testimonials />
			<LetsWorkTogether tagline={pageContext.acf.lets_work_tagline }  buttonText={pageContext.acf.lets_work_button} />
		</div>
	</div>
	</Layout>
);