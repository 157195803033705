import React from 'react';
import { StaticQuery, graphql} from 'gatsby';
import Slider from "react-slick";
import { Fade } from 'react-awesome-reveal';
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true
  };

const testimonialsTemplate  = () => (
    <StaticQuery query={graphql`
    {   
            allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "testimonials"}}}}, limit: 4) {
               edges {
                  node {
                        acf {
                          testimonial
                          client_name
                          client_company
                       }
                    }
               }
            }
    }
    `} render={props => (

        <Fade triggerOnce="true" direction="top" className="content-box testimonial-section viewport-section text-center bg-light-black text-white mb-12 mb-lg-16 mt-sm-10">
            <Slider className="vertical-slider p-8 p-lg-10" {...settings}>
                {props.allWordpressPost.edges.map((post,key) => (
                <div className="slide" key={key}>
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <span className="icon-chatbox font-xl text-pink d-block mb-1"></span>
                            <h2 className="text-white">Why our clients love us</h2>
                            <blockquote className="mb-2">
                                <div className="font-manuale mb-6" dangerouslySetInnerHTML={{ __html: post.node.acf.testimonial}}/>
                                <cite className="fw-400">{post.node.acf.client_name}, {post.node.acf.client_company}</cite>
                            </blockquote>
                        </div>
                    </div>
                </div>
                ))}
                </Slider>
            
            <div className="img-decor bottom-right has-opacity fadeInUp">
                <img src={'/img3.svg'} width="170" height="170" alt="" />
            </div>
        </Fade>	
        )} />

    );
        

export default testimonialsTemplate;
